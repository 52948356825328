<script lang="ts" setup>
import type { Api } from '~~/global'

import { replacePlaceholders } from '~/util/textFunction'
import type { Models } from '~/types/models'
import { useAuthStore } from '~/store/auth'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { $lang } = useNuxtApp()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const Route = useRoute()
const AuthStore = useAuthStore()

const params = reactive({
    page: 1,
    'order-by': 'latest',
})

const { data: categoryResult, error } = await useAsyncData('store-detail-index', () => {
    return $fetch<Api.Responses.Pages.CategoryDetails | Api.Responses.ResourceNotFound>(
        endpoints.pages.category.path.replace('_SLUG_', Route.params.slug as string),
        {
            method: 'GET',
            headers: buildHeaders(AuthStore.SessionToken),
            baseURL,
            params: {
                status: 'active',
                /*  ...params, */
            },
        },
    ).catch((e) => {
        return e.data || false
    })
})
if (error.value || !categoryResult.value || categoryResult.value.error) {
    throw createError({
        statusCode: categoryResult.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            categoryResult.value?.feedback === 'resource_not_found'
                ? 'Página no encontrada'
                : 'Algo salió mal',
    })
}

params.page = 2

const info = categoryResult.value.data

const currentDates = currentDate()
const {
    public: { origin, siteName },
} = useRuntimeConfig()

const [title, description, h1, h2] = replacePlaceholders(
    [
        [':store', info.category.name || ''],
        [':count', String(info.category.all_count) || ''],
        [':month', currentDates.month || ''],
        [':year', String(currentDates.year) || ''],
        [':day', String(currentDates.day) || ''],
        [':site', siteName || ''],
    ],
    [
        info.category.seo?.title || '',
        info.category.seo?.description || '',
        info.category.seo?.h1 || '',
        info.category.seo?.h2 || '',
    ],
)
const metaInfo = {
    title,
    description,
    h1,
    h2,
}

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    ogImage: info.category.image?.url || '',
    ogImageSecureUrl: info.category.image?.url || '',
    twitterImage: info.category.image?.url || '',
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const discountList = ref([...info.discounts.data, ...info.expired_discounts.data]) as Ref<Models.Discount[]>

const anyDiscountExists = computed(() => {
    return discountList.value.length > 0
})

const secondaryInfo = reactive({
    popularStores: null as null | Models.Store[],
    similarCategories: null as null | Models.Category[],
})

onMounted(async () => {
    try {
        const result = await $fetch<
            Api.Responses.Pages.CategorySecondaryDetails | Api.Responses.RouteNotFound
        >(endpoints.pages.category.secondaryData.replace('_SLUG_', Route.params.slug as string), {
            headers: buildHeaders(),
            baseURL,
            method: 'GET',
        })

        if (result.feedback === 'data_success') {
            secondaryInfo.similarCategories = [...result.data.similar_categories]
            secondaryInfo.popularStores = [...result.data.popular_stores]
        }
    } catch (e) {}
})

const breadcrumbs = computed(() => {
    const hierarchy = [] as { path: string; title: string }[]
    if (info.category.parent) {
        hierarchy.push({
            path: `/${$lang.routes.categories}/` + info.category.parent.slug,
            title: info.category.parent.name,
        })
        if (info.category.parent?.parent) {
            hierarchy.push({
                path: `/${$lang.routes.categories}/` + info.category.parent.parent.slug,
                title: info.category.parent.parent.name,
            })
        }
    }
    return [
        {
            path: '/',
            title: 'Home',
        },
        ...hierarchy.reverse(),
        {
            title: info.category.name || '',
        },
    ]
})

const pagination = reactive({
    loading: false,
    show: info.discounts.meta.current_page < info.discounts.meta.last_page,
})

const loadMore = async () => {
    pagination.loading = true

    const result = await $fetch<Api.Responses.Pages.CategoryDetails | Api.Responses.ResourceNotFound>(
        endpoints.pages.category.path.replace('_SLUG_', Route.params.slug as string),
        {
            method: 'GET',
            headers: buildHeaders(AuthStore.SessionToken),
            baseURL,
            params: {
                status: 'active',
                ...params,
            },
        },
    )

    if (result.feedback === 'data_success') {
        discountList.value = [
            ...discountList.value,
            ...result.data.discounts.data,
            ...(result.data.expired_discounts?.data || []),
        ]

        const { current_page, last_page } = result.data.discounts.meta

        if (!last_page || current_page === last_page) {
            pagination.show = false
        } else {
            params.page = current_page + 1
        }
    }

    pagination.loading = false
}
</script>

<template>
    <div class="category-index-page">
        <LayoutAlertMessage
            v-if="info.category.header_messages && info.category.header_messages[0]"
            :info="info.category.header_messages[0]"
        />
        <section
            class="category-index-page-cover"
            :class="{ 'mb-2': !info.category.parent, 'mb-4': info.category.parent }"
        >
            <LayoutBreadcrumbs
                :layers="breadcrumbs"
                text-color="text-gray-900"
                margin="mt-1"
                class="category-index-page-cover__breadcrumbs"
            />
            <div class="category-index-page-cover__content">
                <h1 class="category-index-page-cover__content-title">
                    {{ metaInfo.h1 }}
                </h1>
                <h2 class="category-index-page-cover__content-sub">
                    {{ metaInfo.h2 }}
                </h2>
                <div
                    v-if="!info.category.parent && info.category.main_content"
                    class="category-index-page-cover__content-description"
                    v-html="info.category.main_content"
                ></div>
            </div>
        </section>

        <div v-if="!info.category.parent" class="container mb-5">
            <h2 class="my-3 text-lg font-medium text-gray-800 sm:text-xl">
                {{ $lang.views.categoryDetails.best_week_discounts }}
            </h2>
            <WidgetSlider class="h-[243px] lg:h-[287px]">
                <WidgetSliderItem v-for="(item, key) in info.card_featured_offers" :key="key">
                    <DCardVertical :info="item" :lazy-image="key > 1" />
                </WidgetSliderItem>
            </WidgetSlider>
        </div>

        <div class="category-index-page__content">
            <main class="main-content">
                <section v-if="anyDiscountExists" class="main-content__section">
                    <h2>{{ $lang.views.categoryDetails.new_offers_in }} {{ info.category.name }}</h2>

                    <ul v-if="discountList.length > 0" class="main-content__discount-list">
                        <template v-if="discountList.length">
                            <li
                                v-for="(cta, key) in info.cta_cards || []"
                                :key="'cta-' + key"
                                :style="{ order: cta.position }"
                            >
                                <CTAHandler :cta="cta" />
                            </li>
                        </template>
                        <li v-for="(item, key) in discountList" :key="key" :style="{ order: key + 1 }">
                            <DCardAdmin v-if="item.is_admin" :info="item" :show-brand-logo="true" />
                            <DCardMain v-else :info="item" in-stores-route="true" />
                        </li>
                    </ul>

                    <MiscNoResults
                        v-else
                        :class="discountList.length > 0 ? '' : 'order-3'"
                        mode="discounts"
                        :button="{
                            text: $lang.views.storeDetails.best_coupons_here,
                            path: `/${$lang.routes.coupons}`,
                            icon: $assets.white.couponsMenu,
                            color: 'primary',
                        }"
                        :title="`${$lang.views.storeDetails.we_dont_have} <span class='text-site-primary'>${$lang.views.storeDetails.coupons}</span> ${$lang.views.storeDetails.in_the_store} ${info.category.name}, ${$lang.views.storeDetails.will_be_available}`"
                        :subtitle="$lang.views.storeDetails.best_coupons"
                    />

                    <ButtonLoadMore
                        v-if="pagination.show"
                        :handler="() => loadMore()"
                        :loading="pagination.loading"
                    />
                </section>
                <MiscNoResults
                    v-else
                    mode="megaphone"
                    :title="`${$lang.views.categoryDetails.no_offers_found1}
                    <span class='text-site-primary'>${$lang.views.categoryDetails.offers}</span> ${$lang.views.categoryDetails.in} ${info.category.name}, ${$lang.views.categoryDetails.no_offers_found2}`"
                    :subtitle="$lang.views.categoryDetails.check_offers"
                    :button="{
                        path: `/${$lang.routes.offers}`,
                        text: $lang.views.categoryDetails.best_offers,
                        color: 'primary',
                        icon: $assets.white.bestOffers,
                    }"
                />
            </main>

            <aside class="sidebar-content">
                <LayoutDescription
                    v-if="info.category.content"
                    model="secondary"
                    :info="{
                        content: info.category.content,
                        title: $lang.views.categoryDetails.description,
                    }"
                />
                <LayoutGallery
                    v-if="secondaryInfo.popularStores"
                    :title="$lang.views.categoryDetails.popular_stores"
                    :path="`/${$lang.routes.brands}`"
                    model="secondary"
                    :info="secondaryInfo.popularStores"
                    :max-items="18"
                />
                <LayoutGalleryTwo
                    v-if="secondaryInfo.similarCategories"
                    :title="$lang.views.categoryDetails.similar_categories"
                    :path="`/${$lang.routes.categories}`"
                    :info="secondaryInfo.similarCategories"
                    :max-items="18"
                />
            </aside>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.category-index-page {
    &-cover {
        @apply relative overflow-hidden;
        &__breadcrumbs {
            @apply container;
        }
        &__content {
            @apply container relative pt-2;
            &-title {
                @apply mb-1 text-xl font-medium text-gray-900 sm:text-2xl;
            }
            &-sub {
                @apply text-sm font-medium text-gray-900 sm:text-base;
            }
            &-description {
                @apply mt-2 text-sm text-gray-800;
            }
        }
    }

    &__featured-offers-placeholder {
        @apply container min-h-[335px] pb-5 pt-5 lg:min-h-[335px];
    }

    &__content {
        @apply container mb-5 xl:flex;
        .main-content {
            @apply gap-y-6 xl:order-2 xl:flex-grow;
            &__section {
                @apply rounded-2xl;
                h2 {
                    @apply mb-3 text-lg font-medium text-gray-800 sm:text-xl;
                }
            }
            &__discount-list {
                @apply flex flex-col;
                li {
                    @apply mb-5;
                }
            }
        }
        .sidebar-content {
            @apply mt-4 space-y-5 lg:order-1 xl:mr-5 xl:mt-0 xl:w-80 xl:flex-none;
        }
    }

    &__expired {
        &-title {
            @apply mb-3 mt-10 text-lg font-medium text-gray-800 sm:text-xl;
        }
    }
}
</style>
